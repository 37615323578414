<template>
  <div>
    <ValidationObserver
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <b-card :title="formTitle">
        <template #footer>
          <b-button
            type="submit"
            variant="primary"
            @click="handleSubmit(onSubmit)"
          >
            Submit
          </b-button>
          <b-button
            class="ml-1"
            type="reset"
            variant="secondary"
            @click="cancelForm"
          >
            Cancel
          </b-button>
          <b-button
            class="ml-1"
            type="reset"
            variant="danger"
            @click="resetForm"
          >
            Reset
          </b-button>
        </template>
        <b-form
          v-for="(form, index) in formConfig.form"
          :key="`form-${index})`"
        >
          <b-row>
            <template v-for="(elem, i) in form.elem">
              <b-col
                v-if="!elem.customValidation && elem.show[formMode]"
                :key="`${elem.key}-${i}`"
                :cols="elem.hasOwnProperty('colSize') ? elem.colSize.default : 12"
                :lg="elem.hasOwnProperty('colSize') ? elem.colSize.lg : 6"
              >
                <base-input
                  v-model="actualForm[elem.key]"
                  :vid="actualForm[elem.key]"
                  :rules="elem.rules"
                  :type="elem.type"
                  :name="elem.fieldName"
                  :label="elem.fieldName"
                  :select-option="elem.hasOwnProperty('options') ? elem.options : null"
                />
              </b-col>

              <b-col
                v-if="elem.customValidation && elem.show[formMode]"
                :key="`${elem.key}-${i}`"
                :cols="elem.hasOwnProperty('colSize') ? elem.colSize.default : 12"
                :lg="elem.hasOwnProperty('colSize') ? elem.colSize.lg : 6"
              >
                <div v-if="elem.type === 'switch'">
                  <b-form-checkbox
                    v-model="actualForm[elem.key]"
                    checked="false"
                    :name="elem.fieldName"
                    switch
                    inline
                  >
                    {{ elem.fieldName }}
                  </b-form-checkbox>
                </div>
              </b-col>
            </template>
          </b-row>
        </b-form>
      </b-card>
    </ValidationObserver>
    <pre v-if="debugMode">{{ actualForm }}</pre>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { isEmpty } from 'lodash'
import Config from '@/views/administration/admins/formConfig'
import BaseInput from '@/leanpay-components/BaseInput.vue'

export default {
  components: {
    ValidationObserver,
    BaseInput,
  },
  data() {
    return {
      actualForm: {
        record_status: false,
      },
      formMode: 'create',
    }
  },
  computed: {
    parentPageNamedRoute() {
      return this.$route.matched[0].name
    },
    formTitle() {
      const title = this.formConfig.title[this.formMode]
      return title
    },
    formConfig() {
      if (Config) {
        return Config.formData()
      }
      return {}
    },
  },
  mounted() {
    if (!isEmpty(this.$route.params)) {
      this.formMode = this.$route.params.mode
    }

    if (this.formMode === 'edit'
        || this.formMode === 'remove'
        || this.formMode === 'view') {
      console.log(`${this.formMode} Mode`)
      this.$store.dispatch('banks/getBank', this.$route.params.id).then(({ data }) => {
        console.log(this.formMode, 'hit')
        this.actualForm = data.data
      })
    } else if (this.formMode === 'create') {
      console.log(`${this.formMode} Mode`)
    } else {
      console.log('Unknown Mode')
      this.$router.push({ name: this.parentPageNamedRoute })
    }
  },
  methods: {
    onSubmit() {
      if (this.$route.params.mode === 'edit') {
        const payload = {
          bankId: this.$route.params.id,
          name: this.actualForm.name,
          code: this.actualForm.code,
          bank_branch: this.actualForm.bank_branch,
          account_number: this.actualForm.account_number,
          description: this.actualForm.description,
          bank_account_statement: this.actualForm.bank_account_statement,
          record_status: this.actualForm.record_status,
        }
        this.$store.dispatch('banks/updateBank', payload)
      } else {
        const payload = {
          name: this.actualForm.name,
          code: this.actualForm.code,
          bank_branch: this.actualForm.bank_branch,
          account_number: this.actualForm.account_number,
          description: this.actualForm.description,
          bank_account_statement: this.actualForm.bank_account_statement,
          record_status: this.actualForm.record_status,
        }
        this.$store.dispatch('banks/createBank', payload)
      }
    },
    cancelForm() {
      this.$router.push({ name: this.parentPageNamedRoute })
    },
    resetForm() {
      requestAnimationFrame(() => {
        console.log(this.$refs.observer)
        this.$refs.observer.reset()
      })
    },
  },
}
</script>
